export const Image = ({ title, largeImage, smallImage }) => {
  return (
    <div className="portfolio-item">
      {/* <div className='hover-bg'> */}{" "}
      {/* <a
          href={largeImage}
          title={title}
          data-lightbox-gallery='gallery1'
        > */}
      <img src={smallImage} className="img-responsive1 center" alt={title} />{" "}
      <div className="hover-text">
        <h6 className="image-text">{title}</h6>
      </div>
      {/* </a>{' '} */}
      {/* </div> */}
    </div>
  );
};
