import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Client</h2>
          <p>
            Terimkasih atas kepercayaan yang telah diberikan, sekarang waktunya
            anda percaya kepada kami
          </p>
        </div>
        <div className="row">
          {/* <div className='portfolio-items'> */}
          {props.data
            ? props.data.map((d, i) => {
              console.log(d);
              return (
                <div key={`${d.title}-${i}`} className="col-sm-3">
                  <Image title={d.title} smallImage={d.smallImage} />
                </div>
              );
            })
            : "Loading..."}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
