// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.min.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Autoplay, Pagination, Navigation } from "swiper";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>TEAM</h2>
        </div>
        <div className="row">
          {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-sm-3">
                  <div className="thumbnail">
                    <img src={d.img} alt="" className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"} */}

          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              dynamicBullets: true,
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
            freeMode={true}
            loop={true}
            modules={[Autoplay, Pagination, Navigation]}
            navigation={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            className="mySwiper"
            style={{
              "--swiper-pagination-color": "#ff0000",
              "--swiper-navigation-color": "#ff0000",
            }}
          >
            {props.data
              ? props.data.map((d, i) => (
                  <SwiperSlide>
                    <div key={`${d.name}-${i}`}>
                      <div className="thumbnail">
                        <img src={d.img} alt="" className="team-img" />
                        <div className="caption">
                          <h4>{d.name}</h4>
                          <p>{d.job}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              : "loading"}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
